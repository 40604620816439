var render = function () {
  var _vm$bracketNode, _vm$bracketNode$playe, _vm$bracketNode2, _vm$bracketNode2$play, _vm$bracketNode3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('div', {
    staticClass: "vtb-item-players",
    staticStyle: {
      "max-width": "200px"
    }
  }, [_c('div', [_c('div', {
    class: ['d-flex justify-content-between', 'vtb-player', 'vtb-player1', _vm.getPlayerClass(_vm.bracketNode.player1)],
    on: {
      "mouseleave": _vm.unhighlightPlayer,
      "mouseover": function mouseover($event) {
        return _vm.highlightPlayer(_vm.bracketNode.player1.id);
      }
    }
  }, [_c('div', {
    staticClass: "mr-1 text-truncate",
    class: [_vm.getScoreClass(_vm.bracketNode.player1)]
  }, [_vm._t("player", null, {
    "player": _vm.bracketNode.player1
  })], 2), _c('div', [_c('span', {
    class: [_vm.getScoreClass(_vm.bracketNode.player1)]
  }, [_vm._v(" " + _vm._s((_vm$bracketNode = _vm.bracketNode) === null || _vm$bracketNode === void 0 ? void 0 : (_vm$bracketNode$playe = _vm$bracketNode.player1) === null || _vm$bracketNode$playe === void 0 ? void 0 : _vm$bracketNode$playe.score) + " ")])])]), _c('div', {
    class: ['d-flex justify-content-between', 'vtb-player', 'vtb-player2', _vm.getPlayerClass(_vm.bracketNode.player2)],
    on: {
      "mouseleave": _vm.unhighlightPlayer,
      "mouseover": function mouseover($event) {
        return _vm.highlightPlayer(_vm.bracketNode.player2.id);
      }
    }
  }, [_c('div', {
    staticClass: "mr-1 text-truncate",
    class: [_vm.getScoreClass(_vm.bracketNode.player2)]
  }, [_vm._t("player", null, {
    "player": _vm.bracketNode.player2
  })], 2), _c('div', {}, [_c('span', {
    class: [_vm.getScoreClass(_vm.bracketNode.player2)]
  }, [_vm._v(" " + _vm._s((_vm$bracketNode2 = _vm.bracketNode) === null || _vm$bracketNode2 === void 0 ? void 0 : (_vm$bracketNode2$play = _vm$bracketNode2.player2) === null || _vm$bracketNode2$play === void 0 ? void 0 : _vm$bracketNode2$play.score) + " ")])])])]), _vm._t("player-extension-bottom", null, {
    "match": _vm.matchProperties
  })], 2), _vm.showResultsButtonLocal === true ? _c('div', {
    staticClass: "d-flex align-items-center",
    staticStyle: {
      "margin-left": "3px"
    }
  }, [_c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "size": "sm",
      "variant": "flat-secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.matchResultClick(_vm.bracketNode);
      }
    }
  }, [_vm.isLoadingMatch && ((_vm$bracketNode3 = _vm.bracketNode) === null || _vm$bracketNode3 === void 0 ? void 0 : _vm$bracketNode3.matchId) === _vm.loadingMatchId ? _c('b-spinner', {
    staticStyle: {
      "height": "24px",
      "width": "24px"
    },
    attrs: {
      "label": "Loading...",
      "small": ""
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "InfoIcon",
      "size": "24"
    }
  })], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }