<template>
  <b-modal
    id="organizer-match-modal"
    ref="organizer-match-modal"
    :title="title"
    no-close-on-backdrop
    size="lg"
    @hidden="() => {
      opponent1MatchResult = null
      opponent2MatchResult = null
      scoreOpponent1 = null
      scoreOpponent2 = null
      forfeitOpponent1 = false
      forfeitOpponent2 = false
      showErrorNotifications = false
      showSuccessNotifications = false
      error = null
    }"
  >
    <template #modal-footer="{ cancel }">
      <div class="d-flex flex-grow-1 justify-content-end">
        <b-button
          v-if="showSaveButton"
          variant="primary"
          class="mr-1"
          @click="saveMatchResults"
        >
          <b-spinner
            v-if="saving"
            small
          />
          <span v-else>Save and close</span>
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Close
        </b-button>
      </div>
    </template>

    <b-row>
      <!-- opponent 1 -->
      <b-col
        class="d-flex flex-column align-items-center"
        cols="5"
      >
        <!-- opponent info -->
        <div class="d-flex flex-column align-items-center">
          <b-avatar
            :src="match?.opponent1?.avatar"
            size="80"
            variant="secondary"
          />

          <b-card-text class="mb-0 font-weight-bold mt-2">
            {{
              match?.opponent1?.participant?.name || match?.opponent1?.name || 'TBD'
            }}
          </b-card-text>

          <b-list-group class="mt-2 flex-wrap flex-row border-top">
            <b-list-group-item
              v-for="lineup in match?.opponent1?.lineup"
              :key="lineup.player.id"
              tag="div"
            >
              <div class="d-flex align-items-center">
                <b-badge variant="light-primary">
                  {{ lineup.player.name }}
                </b-badge>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <!-- opponent match result -->
        <div class="mt-1 d-flex justify-content-center  mt-auto">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent1MatchResult === 'W'
                  ? 'success'
                  : 'outline-primary'
              "
              @click="setMatchResult1('W')"
            >
              W
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent1MatchResult === 'D'
                  ? 'secondary'
                  : 'outline-primary'
              "
              @click="setMatchResult1('D')"
            >
              D
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent1MatchResult === 'L' ? 'danger' : 'outline-primary'
              "
              @click="setMatchResult1('L')"
            >
              L
            </b-button>
          </b-button-group>
        </div>

        <div class="mt-1 d-flex justify-content-center">
          <b-form-input
            v-model="scoreOpponent1"
            class="w-50 text-center"
            style="border: 1px solid #28C76F"
            @change="setStatus"
          />
        </div>
      </b-col>

      <b-col class="d-flex align-items-center justify-content-center">
        <div class="d-flex flex-column align-items-center">
          <h3>VS</h3>
        </div>
      </b-col>

      <!-- opponent 2 -->
      <b-col
        class="d-flex flex-column align-items-center"
        cols="5"
      >
        <!-- opponent2 info -->
        <div class="d-flex flex-column align-items-center">
          <b-avatar
            :src="match?.opponent2?.avatar"
            size="80"
            variant="secondary"
          />

          <b-card-text class="mb-0 font-weight-bold mt-2">
            {{
              match?.opponent2?.participant?.name || match?.opponent2?.name || 'TBD'
            }}
          </b-card-text>

          <b-list-group class="mt-2 flex-wrap flex-row border-top">
            <b-list-group-item
              v-for="listItem in match?.opponent2?.lineup"
              :key="listItem.id"
              tag="div"
            >
              <div class="d-flex align-items-center">
                <b-badge variant="light-primary">
                  {{ listItem.player.name }}
                </b-badge>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>

        <!-- opponent2 match result -->
        <div class="mt-1 d-flex justify-content-center mt-auto">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent2MatchResult === 'W'
                  ? 'success'
                  : 'outline-primary'
              "
            >
              W
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent2MatchResult === 'D'
                  ? 'secondary'
                  : 'outline-primary'
              "
              @click="setMatchResult2('D')"
            >
              D
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="
                opponent2MatchResult === 'L' ? 'danger' : 'outline-primary'
              "
              @click="setMatchResult2('L')"
            >
              L
            </b-button>
          </b-button-group>
        </div>

        <div class="mt-1 d-flex justify-content-center">
          <b-form-input
            v-model="scoreOpponent2"
            class="w-50 text-center"
            type="number"
            style="border: 1px solid #28C76F"
            @change="setStatus"
          />
        </div>
      </b-col>
    </b-row>

    <b-row v-if="match?.results">
      <b-col class="d-flex justify-content-center mt-5">
        <h4>Match was not yet played or finished.</h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="justify-content-center d-flex" />
    </b-row>

    <b-row
      v-if="opponent1Results"
      class="mt-2"
    >
      <b-col sm="2" />
      <b-col sm="4">
        {{ match?.opponent1?.participant?.name || match?.opponent1?.name }} entered: {{
          opponent1Results.opponent1_score === null ? ' ? ' : opponent1Results.opponent1_score
        }} [{{ opponent1Results.opponent1_result }}] : {{ opponent1Results.opponent2_score || ' ? ' }} [{{ opponent1Results.opponent2_result }}]
      </b-col>
      <b-col>
        <b-button
          variant="success"
          size="sm"
          @click="openScreenshot(opponent1Results.score_screenshot)"
        >
          Open screenshot
        </b-button>
      </b-col>
    </b-row>

    <b-row
      v-else
      class="mt-1"
    >
      <b-col sm="2" />
      <b-col
        v-if="match?.opponent1"
        sm="4"
      >
        <p>No results entered by {{ match?.opponent1?.participant?.name || match?.opponent1?.name }} </p>
      </b-col>
    </b-row>

    <b-row
      v-if="opponent2Results"
      class="mt-1"
    >
      <b-col sm="2" />
      <b-col sm="4">
        {{ match?.opponent2?.participant?.name || match?.opponent2?.name }} entered: {{
          opponent2Results.opponent1_score === null ? ' ? ' : opponent2Results.opponent1_score
        }} [{{ opponent2Results.opponent1_result }}] : {{ opponent2Results.opponent2_score || ' ? ' }}  [{{ opponent2Results.opponent2_result }}]
      </b-col>
      <b-col>
        <b-button
          variant="success"
          size="sm"
          @click="openScreenshot(opponent2Results.score_screenshot)"
        >
          Open screenshot
        </b-button>
      </b-col>
    </b-row>

    <b-row
      v-else
      class="mt-1"
    >
      <b-col sm="2" />
      <b-col
        v-if="match?.opponent2"
        sm="4"
      >
        <p>No results entered by {{ match?.opponent2?.participant?.name || match?.opponent2?.name }} </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <dismissible-message-box
          :show="showErrorNotifications"
          class="mb-1 p-1"
          variant="danger"
          @onDismissibleMessageBoxDismissed="showErrorNotifications = false"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          {{ error }}
        </dismissible-message-box>

        <dismissible-message-box
          :show="showSuccessNotifications"
          class="mb-1 p-1"
          variant="success"
          @onDismissibleMessageBoxDismissed="showSuccessNotifications = false"
        >
          Successfully saved match results
        </dismissible-message-box>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import {
  BAvatar, BBadge,
  BButton,
  BButtonGroup,
  BCardText,
  BCol,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BModal,
  BRow, BSpinner,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'

export default {
  components: {
    DismissibleMessageBox,
    BModal,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BCardText,
    BListGroupItem,
    BListGroup,
    BButtonGroup,
    BFormInput,
    BBadge,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    match: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      tabIndex: 0,
      showSaveButton: true,
      forfeitOpponent1: false,
      forfeitOpponent2: false,
      scoreOpponent1: null,
      scoreOpponent2: null,
      opponent1MatchResult: null,
      opponent2MatchResult: null,
      showErrorNotifications: false,
      showSuccessNotifications: false,
      successMessage: null,
      error: null,
      saving: false,
    }
  },
  computed: {
    opponent1Results() {
      return this.match?.result?.participant_results.find(
        result => result.participant_id === this.match.opponent1.id,
      )
    },
    opponent2Results() {
      return this.match?.result?.participant_results.find(
        result => result.participant_id === this.match.opponent2.id,
      )
    },
    title() {
      return `Edit match ${
          this.match?.opponent1?.participant?.name || this.match?.opponent1?.name || 'TBD'
      } VS ${this.match?.opponent2?.participant?.name || this.match?.opponent2?.name || 'TBD'} `
    },
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  watch: {
    match(val) {
      this.opponent1MatchResult = val?.result?.opponent1_result || null
      this.opponent2MatchResult = val?.result?.opponent2_result || null

      this.scoreOpponent1 = val?.result?.opponent1_score || null
      this.scoreOpponent2 = val?.result?.opponent2_score || null

      this.forfeitOpponent1 = val?.result?.opponent1_forfeit || false
      this.forfeitOpponent2 = val?.result?.opponent2_forfeit || false
    },
  },
  methods: {
    openScreenshot(screenshotUrl) {
      if (!screenshotUrl) {
        // eslint-disable-next-line no-alert
        alert('No screenshot provided!')

        return
      }

      window.open(screenshotUrl, '_blank')
    },
    setMatchResult1(status) {
      switch (status) {
        case 'W':
          this.opponent1MatchResult = 'W'
          this.opponent2MatchResult = 'L'
          break
        case 'D':
          this.opponent1MatchResult = 'D'
          this.opponent2MatchResult = 'D'
          break
        case 'L':
          this.opponent1MatchResult = 'L'
          this.opponent2MatchResult = 'W'
          break
        default:
          this.opponent1MatchResult = null
          this.opponent2MatchResult = null
          break
      }

      this.forfeitOpponent1 = null
      this.forfeitOpponent2 = null
    },
    setMatchResult2(status) {
      switch (status) {
        case 'W':
          this.opponent2MatchResult = 'W'
          this.opponent1MatchResult = 'L'
          break
        case 'D':
          this.opponent2MatchResult = 'D'
          this.opponent1MatchResult = 'D'
          break
        case 'L':
          this.opponent2MatchResult = 'L'
          this.opponent1MatchResult = 'W'
          break
        default:
          this.opponent1MatchResult = null
          this.opponent2MatchResult = null
          break
      }

      this.forfeitOpponent1 = null
      this.forfeitOpponent2 = null
    },

    setStatus() {
      if (this.scoreOpponent1 === null || this.scoreOpponent2 === null) {
        this.opponent1MatchResult = null
        this.opponent2MatchResult = null

        return
      }

      if (this.scoreOpponent1 === this.scoreOpponent2) {
        this.opponent1MatchResult = 'D'
        this.opponent2MatchResult = 'D'

        return
      }

      if (this.scoreOpponent1 > this.scoreOpponent2) {
        this.opponent1MatchResult = 'W'
        this.opponent2MatchResult = 'L'

        return
      }

      if (this.scoreOpponent1 < this.scoreOpponent2) {
        this.opponent1MatchResult = 'L'
        this.opponent2MatchResult = 'W'
      }
    },
    async saveMatchResults() {
      this.error = null
      this.showErrorNotifications = false
      this.showSuccessNotifications = false

      if (!this.opponent1MatchResult || !this.opponent2MatchResult) {
        this.error = 'Please enter match results'
        this.showErrorNotifications = true

        return
      }

      if (!!(this.match.opponent1.id) === false || !!(this.match.opponent2.id) === false) {
        this.error = 'Participants are yet to be determined.'
        this.showErrorNotifications = true

        return
      }

      const payload = {
        match_id: Number(this.match.id),
        opponent1_score: this.scoreOpponent1
          ? Number(this.scoreOpponent1)
          : null,
        opponent2_score: this.scoreOpponent2
          ? Number(this.scoreOpponent2)
          : null,
        opponent1_result: this.opponent1MatchResult,
        opponent2_result: this.opponent2MatchResult,
        status: 'complete',
      }

      // ToDo: when dispute fill dispute_resolved_by
      // dispute_resolved_by: this.match?.result?.dispute_resolved_by || getMyTournamentStaffId(this.match?.tournament),

      this.saving = true

      const { errors } = await this.$api.tournament.saveMatchResult(payload)

      this.saving = false

      if (errors) {
        this.error = 'There was a problem saving match result. Please try again or contact our support.'

        this.showErrorNotifications = true

        return
      }

      this.showSuccessNotifications = true

      this.$emit('onAdminMatchResultSaved', payload)

      setTimeout(() => {
        this.$refs['organizer-match-modal'].hide()
      }, 3000)
    },
  },
}
</script>

<style lang="scss">
.modal-xl {
  margin-left: 17%;
  margin-right: 17%;
}

.modal .modal-header .close {
  transform: translate(-4px, 5px);

  &:hover,
  &:focus,
  &:active {
    transform: translate(-7px, 6px);
  }
}

@import '~@core/scss/base/bootstrap-extended/_variables.scss';

.dark-layout {
  .list-group {
    border-radius: 0;

    .list-group-item {
      border: none;
      background: none !important;

      flex: 1;
    }
  }
}
</style>
