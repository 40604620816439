<template>
  <b-modal
    id="round-settings-modal"
    :title="title"
    size="md"
    no-close-on-backdrop
    @hidden="resetModal"
  >
    <!-- Round settings -->
    <b-row class="flex-column">

      <b-col>
        <b-form-group
          label="Title"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="roundLocal.name"
            class="d-inline-block mr-1"
            placeholder="name"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          label="Best of"
          label-for="bestOf"
        >
          <v-select
            id="bestOf"
            v-model="roundLocal.format_id"
            :options="matchFormatOptions"
            :reduce="format => format.code"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <template #modal-footer="{ cancel }">
      <b-row class="flex-grow-1 flex-column">
        <b-col>
          <dismissible-message-box
            :show="showNotifications"
            :variant="notificationType"
            class="p-1"
            @onDismissibleMessageBoxDismissed="showNotifications = false"
          >
            <feather-icon
              class="mr-50"
              icon="InfoIcon"
            />
            {{ message }}
          </dismissible-message-box>
        </b-col>
        <b-col>
          <spinning-button
            :is-loading="saving"
            @click.native="handleSaveSettings"
          >
            Save
          </spinning-button>
          <b-button
            class="ml-1"
            variant="outline-primary"
            @click="cancel"
          >
            Close
          </b-button>
        </b-col>
      </b-row>

    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol, BFormGroup, BFormInput,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SpinningButton from '@/components/SpinningButton.vue'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import vSelect from 'vue-select'
import { fetchMatchTypeOptions } from '@/api/tournament/tournamentService'

export default {
  components: {
    BFormInput,
    BFormGroup,
    vSelect,
    BButton,
    DismissibleMessageBox,
    SpinningButton,
    BModal,
    BRow,
    BCol,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    round: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      MessageType,
      showNotifications: false,
      notificationType: MessageType.SUCCESS,
      message: null,
      roundLocal: this.round || {
        name: null,
        format_id: null,
      },
      matchFormatOptions: fetchMatchTypeOptions(),
      saving: false,
    }
  },
  computed: {
    title() {
      return `Round ${this.round?.index}`
    },
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  watch: {
    round(newVal) {
      this.roundLocal = newVal
    },
  },
  methods: {
    async resetModal() {
      this.roundLocal = {
        name: null,
        format_id: null,
      }
      this.error = null
      this.message = null
      this.showNotifications = false
    },

    async handleSaveSettings() {
      this.message = null
      this.showNotifications = false

      this.saving = true

      const { success } = await this.$api.tournament.saveRoundSettings({
        id: this.roundLocal.id,
        name: this.roundLocal.name,
        format_id: this.roundLocal.format_id,
      })

      this.saving = false

      if (success === false) {
        this.message = 'There was an error saving round settings. Please try again later or contact our support.'
        this.notificationType = MessageType.ERROR
        this.showNotifications = true

        return
      }

      this.message = 'Round settings saved successfully.'
      this.notificationType = MessageType.SUCCESS
      this.showNotifications = true

      setTimeout(() => {
        this.$bvModal.hide('round-settings-modal')

        this.$emit('onRoundSettingsSaved', this.roundLocal)
      }, 2500)
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
