var render = function () {
  var _vm$match, _vm$match$opponent, _vm$match2, _vm$match2$opponent, _vm$match2$opponent$p, _vm$match3, _vm$match3$opponent, _vm$match4, _vm$match4$opponent, _vm$match5, _vm$match5$opponent, _vm$match6, _vm$match6$opponent, _vm$match6$opponent$p, _vm$match7, _vm$match7$opponent, _vm$match8, _vm$match8$opponent, _vm$match9, _vm$match10, _vm$match10$opponent, _vm$match10$opponent$, _vm$match11, _vm$match11$opponent, _vm$match12, _vm$match13, _vm$match13$opponent, _vm$match13$opponent$, _vm$match14, _vm$match14$opponent, _vm$match15, _vm$match15$opponent, _vm$match15$opponent$, _vm$match16, _vm$match16$opponent, _vm$match17, _vm$match18, _vm$match18$opponent, _vm$match18$opponent$, _vm$match19, _vm$match19$opponent;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "organizer-match-modal",
    attrs: {
      "id": "organizer-match-modal",
      "title": _vm.title,
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": function hidden() {
        _vm.opponent1MatchResult = null;
        _vm.opponent2MatchResult = null;
        _vm.scoreOpponent1 = null;
        _vm.scoreOpponent2 = null;
        _vm.forfeitOpponent1 = false;
        _vm.forfeitOpponent2 = false;
        _vm.showErrorNotifications = false;
        _vm.showSuccessNotifications = false;
        _vm.error = null;
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "d-flex flex-grow-1 justify-content-end"
        }, [_vm.showSaveButton ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.saveMatchResults
          }
        }, [_vm.saving ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('span', [_vm._v("Save and close")])], 1) : _vm._e(), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)];
      }
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$match = _vm.match) === null || _vm$match === void 0 ? void 0 : (_vm$match$opponent = _vm$match.opponent1) === null || _vm$match$opponent === void 0 ? void 0 : _vm$match$opponent.avatar,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('b-card-text', {
    staticClass: "mb-0 font-weight-bold mt-2"
  }, [_vm._v(" " + _vm._s(((_vm$match2 = _vm.match) === null || _vm$match2 === void 0 ? void 0 : (_vm$match2$opponent = _vm$match2.opponent1) === null || _vm$match2$opponent === void 0 ? void 0 : (_vm$match2$opponent$p = _vm$match2$opponent.participant) === null || _vm$match2$opponent$p === void 0 ? void 0 : _vm$match2$opponent$p.name) || ((_vm$match3 = _vm.match) === null || _vm$match3 === void 0 ? void 0 : (_vm$match3$opponent = _vm$match3.opponent1) === null || _vm$match3$opponent === void 0 ? void 0 : _vm$match3$opponent.name) || 'TBD') + " ")]), _c('b-list-group', {
    staticClass: "mt-2 flex-wrap flex-row border-top"
  }, _vm._l((_vm$match4 = _vm.match) === null || _vm$match4 === void 0 ? void 0 : (_vm$match4$opponent = _vm$match4.opponent1) === null || _vm$match4$opponent === void 0 ? void 0 : _vm$match4$opponent.lineup, function (lineup) {
    return _c('b-list-group-item', {
      key: lineup.player.id,
      attrs: {
        "tag": "div"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(lineup.player.name) + " ")])], 1)]);
  }), 1)], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center  mt-auto"
  }, [_c('b-button-group', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent1MatchResult === 'W' ? 'success' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult1('W');
      }
    }
  }, [_vm._v(" W ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent1MatchResult === 'D' ? 'secondary' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult1('D');
      }
    }
  }, [_vm._v(" D ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent1MatchResult === 'L' ? 'danger' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult1('L');
      }
    }
  }, [_vm._v(" L ")])], 1)], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center"
  }, [_c('b-form-input', {
    staticClass: "w-50 text-center",
    staticStyle: {
      "border": "1px solid #28C76F"
    },
    on: {
      "change": _vm.setStatus
    },
    model: {
      value: _vm.scoreOpponent1,
      callback: function callback($$v) {
        _vm.scoreOpponent1 = $$v;
      },
      expression: "scoreOpponent1"
    }
  })], 1)]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('h3', [_vm._v("VS")])])]), _c('b-col', {
    staticClass: "d-flex flex-column align-items-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$match5 = _vm.match) === null || _vm$match5 === void 0 ? void 0 : (_vm$match5$opponent = _vm$match5.opponent2) === null || _vm$match5$opponent === void 0 ? void 0 : _vm$match5$opponent.avatar,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('b-card-text', {
    staticClass: "mb-0 font-weight-bold mt-2"
  }, [_vm._v(" " + _vm._s(((_vm$match6 = _vm.match) === null || _vm$match6 === void 0 ? void 0 : (_vm$match6$opponent = _vm$match6.opponent2) === null || _vm$match6$opponent === void 0 ? void 0 : (_vm$match6$opponent$p = _vm$match6$opponent.participant) === null || _vm$match6$opponent$p === void 0 ? void 0 : _vm$match6$opponent$p.name) || ((_vm$match7 = _vm.match) === null || _vm$match7 === void 0 ? void 0 : (_vm$match7$opponent = _vm$match7.opponent2) === null || _vm$match7$opponent === void 0 ? void 0 : _vm$match7$opponent.name) || 'TBD') + " ")]), _c('b-list-group', {
    staticClass: "mt-2 flex-wrap flex-row border-top"
  }, _vm._l((_vm$match8 = _vm.match) === null || _vm$match8 === void 0 ? void 0 : (_vm$match8$opponent = _vm$match8.opponent2) === null || _vm$match8$opponent === void 0 ? void 0 : _vm$match8$opponent.lineup, function (listItem) {
    return _c('b-list-group-item', {
      key: listItem.id,
      attrs: {
        "tag": "div"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(listItem.player.name) + " ")])], 1)]);
  }), 1)], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center mt-auto"
  }, [_c('b-button-group', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent2MatchResult === 'W' ? 'success' : 'outline-primary'
    }
  }, [_vm._v(" W ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent2MatchResult === 'D' ? 'secondary' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult2('D');
      }
    }
  }, [_vm._v(" D ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": _vm.opponent2MatchResult === 'L' ? 'danger' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        return _vm.setMatchResult2('L');
      }
    }
  }, [_vm._v(" L ")])], 1)], 1), _c('div', {
    staticClass: "mt-1 d-flex justify-content-center"
  }, [_c('b-form-input', {
    staticClass: "w-50 text-center",
    staticStyle: {
      "border": "1px solid #28C76F"
    },
    attrs: {
      "type": "number"
    },
    on: {
      "change": _vm.setStatus
    },
    model: {
      value: _vm.scoreOpponent2,
      callback: function callback($$v) {
        _vm.scoreOpponent2 = $$v;
      },
      expression: "scoreOpponent2"
    }
  })], 1)])], 1), (_vm$match9 = _vm.match) !== null && _vm$match9 !== void 0 && _vm$match9.results ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center mt-5"
  }, [_c('h4', [_vm._v("Match was not yet played or finished.")])])], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    staticClass: "justify-content-center d-flex"
  })], 1), _vm.opponent1Results ? _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "sm": "2"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_vm._v(" " + _vm._s(((_vm$match10 = _vm.match) === null || _vm$match10 === void 0 ? void 0 : (_vm$match10$opponent = _vm$match10.opponent1) === null || _vm$match10$opponent === void 0 ? void 0 : (_vm$match10$opponent$ = _vm$match10$opponent.participant) === null || _vm$match10$opponent$ === void 0 ? void 0 : _vm$match10$opponent$.name) || ((_vm$match11 = _vm.match) === null || _vm$match11 === void 0 ? void 0 : (_vm$match11$opponent = _vm$match11.opponent1) === null || _vm$match11$opponent === void 0 ? void 0 : _vm$match11$opponent.name)) + " entered: " + _vm._s(_vm.opponent1Results.opponent1_score === null ? ' ? ' : _vm.opponent1Results.opponent1_score) + " [" + _vm._s(_vm.opponent1Results.opponent1_result) + "] : " + _vm._s(_vm.opponent1Results.opponent2_score || ' ? ') + " [" + _vm._s(_vm.opponent1Results.opponent2_result) + "] ")]), _c('b-col', [_c('b-button', {
    attrs: {
      "variant": "success",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.openScreenshot(_vm.opponent1Results.score_screenshot);
      }
    }
  }, [_vm._v(" Open screenshot ")])], 1)], 1) : _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "2"
    }
  }), (_vm$match12 = _vm.match) !== null && _vm$match12 !== void 0 && _vm$match12.opponent1 ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('p', [_vm._v("No results entered by " + _vm._s(((_vm$match13 = _vm.match) === null || _vm$match13 === void 0 ? void 0 : (_vm$match13$opponent = _vm$match13.opponent1) === null || _vm$match13$opponent === void 0 ? void 0 : (_vm$match13$opponent$ = _vm$match13$opponent.participant) === null || _vm$match13$opponent$ === void 0 ? void 0 : _vm$match13$opponent$.name) || ((_vm$match14 = _vm.match) === null || _vm$match14 === void 0 ? void 0 : (_vm$match14$opponent = _vm$match14.opponent1) === null || _vm$match14$opponent === void 0 ? void 0 : _vm$match14$opponent.name)) + " ")])]) : _vm._e()], 1), _vm.opponent2Results ? _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "2"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_vm._v(" " + _vm._s(((_vm$match15 = _vm.match) === null || _vm$match15 === void 0 ? void 0 : (_vm$match15$opponent = _vm$match15.opponent2) === null || _vm$match15$opponent === void 0 ? void 0 : (_vm$match15$opponent$ = _vm$match15$opponent.participant) === null || _vm$match15$opponent$ === void 0 ? void 0 : _vm$match15$opponent$.name) || ((_vm$match16 = _vm.match) === null || _vm$match16 === void 0 ? void 0 : (_vm$match16$opponent = _vm$match16.opponent2) === null || _vm$match16$opponent === void 0 ? void 0 : _vm$match16$opponent.name)) + " entered: " + _vm._s(_vm.opponent2Results.opponent1_score === null ? ' ? ' : _vm.opponent2Results.opponent1_score) + " [" + _vm._s(_vm.opponent2Results.opponent1_result) + "] : " + _vm._s(_vm.opponent2Results.opponent2_score || ' ? ') + " [" + _vm._s(_vm.opponent2Results.opponent2_result) + "] ")]), _c('b-col', [_c('b-button', {
    attrs: {
      "variant": "success",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.openScreenshot(_vm.opponent2Results.score_screenshot);
      }
    }
  }, [_vm._v(" Open screenshot ")])], 1)], 1) : _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "2"
    }
  }), (_vm$match17 = _vm.match) !== null && _vm$match17 !== void 0 && _vm$match17.opponent2 ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('p', [_vm._v("No results entered by " + _vm._s(((_vm$match18 = _vm.match) === null || _vm$match18 === void 0 ? void 0 : (_vm$match18$opponent = _vm$match18.opponent2) === null || _vm$match18$opponent === void 0 ? void 0 : (_vm$match18$opponent$ = _vm$match18$opponent.participant) === null || _vm$match18$opponent$ === void 0 ? void 0 : _vm$match18$opponent$.name) || ((_vm$match19 = _vm.match) === null || _vm$match19 === void 0 ? void 0 : (_vm$match19$opponent = _vm$match19.opponent2) === null || _vm$match19$opponent === void 0 ? void 0 : _vm$match19$opponent.name)) + " ")])]) : _vm._e()], 1), _c('b-row', [_c('b-col', [_c('dismissible-message-box', {
    staticClass: "mb-1 p-1",
    attrs: {
      "show": _vm.showErrorNotifications,
      "variant": "danger"
    },
    on: {
      "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
        _vm.showErrorNotifications = false;
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.error) + " ")], 1), _c('dismissible-message-box', {
    staticClass: "mb-1 p-1",
    attrs: {
      "show": _vm.showSuccessNotifications,
      "variant": "success"
    },
    on: {
      "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
        _vm.showSuccessNotifications = false;
      }
    }
  }, [_vm._v(" Successfully saved match results ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }