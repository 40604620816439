<template>
  <div class="d-flex flex-row">
    <div
      class="vtb-item-players"
      style="max-width: 200px"
    >
      <div>
        <div
          :class="[
            'd-flex justify-content-between',
            'vtb-player',
            'vtb-player1',
            getPlayerClass(bracketNode.player1)
          ]"
          @mouseleave="unhighlightPlayer"
          @mouseover="highlightPlayer(bracketNode.player1.id)"
        >
          <div
            class="mr-1 text-truncate"
            :class="[getScoreClass(bracketNode.player1)]"
          >
            <slot
              :player="bracketNode.player1"
              name="player"
            />
          </div>
          <div>
            <span :class="[getScoreClass(bracketNode.player1)]">
              {{ bracketNode?.player1?.score }}
            </span>
          </div>
        </div>

        <div
          :class="[
            'd-flex justify-content-between',
            'vtb-player',
            'vtb-player2',
            getPlayerClass(bracketNode.player2)
          ]"
          @mouseleave="unhighlightPlayer"
          @mouseover="highlightPlayer(bracketNode.player2.id)"
        >
          <div
            class="mr-1 text-truncate"
            :class="[getScoreClass(bracketNode.player2)]"
          >

            <slot
              :player="bracketNode.player2"
              name="player"
            />
          </div>
          <div class="">
            <span :class="[getScoreClass(bracketNode.player2)]">
              {{ bracketNode?.player2?.score }}
            </span>
          </div>
        </div>
      </div>
      <slot
        :match="matchProperties"
        name="player-extension-bottom"
      />
    </div>

    <div
      v-if="showResultsButtonLocal === true"
      class="d-flex align-items-center"
      style="margin-left: 3px;"
    >
      <b-button
        class="btn-icon"
        size="sm"
        variant="flat-secondary"
        @click="matchResultClick(bracketNode)"
      >
        <b-spinner
          v-if="isLoadingMatch && bracketNode?.matchId === loadingMatchId"
          label="Loading..."
          small
          style="height: 24px; width: 24px;"
        />
        <feather-icon
          v-else
          icon="InfoIcon"
          size="24"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  name: 'GamePlayers',
  components: { BSpinner, BButton },
  // eslint-disable-next-line vue/require-prop-types
  props: ['bracketNode', 'highlightedPlayerId', 'showResultsButton', 'isLoadingMatch', 'loadingMatchId', 'organizerMode'],
  data() {
    return {
      showResultsButtonLocal: this.showResultsButton || false,
    }
  },
  computed: {
    matchProperties() {
      return {
        ...this.bracketNode,
        games: undefined,
        hasParent: undefined,
      }
    },
  },
  watch: {
    showEditResults(val) {
      this.showResultsButtonLocal = val
    },
  },
  methods: {
    getPlayerClass(player) {
      let clazz = ''

      if (player?.myParticipant === true && this.organizerMode === false) {
        clazz += ' my-participant'
      }

      if (player.winner === null || player.winner === undefined) {
        return clazz
      }

      clazz += player.winner ? 'winner' : 'defeated'

      if (this.highlightedPlayerId === player.id) {
        clazz += ' highlight'
      }

      return clazz
    },
    getScoreClass(player) {
      if (player.result === null || player.result === undefined) {
        return 'score-default'
      }

      return player.result === 'W' ? 'score-winner' : 'score-default'
    },
    highlightPlayer(playerId) {
      this.$emit('onSelectedPlayer', playerId)
    },
    unhighlightPlayer() {
      this.$emit('onDeselectedPlayer')
    },
    matchResultClick(bracketNode) {
      this.$emit('onMatchResultClick', bracketNode?.matchId)
    },
  },
}
</script>

<style>
  .score-winner {
    color: #27ae60;
  }

  .score-default {
    color: #fff;
  }

  .my-participant {
    background: rgba(115,103,240,.12) !important;
  }

</style>
